<template>
	<form>
		<div>
			<div class="form-row">
				<div class="form-group col-md-6">
					<label>Tag *</label>
					<b-form-input type="text" v-model="tagInput">
					</b-form-input>
				</div>

				<div class="form-group col-md-6">
					<label>Descrição: </label>
					<b-form-input type="text" v-model="descricaoInput"></b-form-input>
				</div>

				<div class="form-group col-md-6">
					<label for="i_nome">Grupo *</label>
					<vue-multi-select
						class="multi-100"
						v-model="grupoSelecionado"
						search
						historyButton
						:selectOptions="gruposOpcoes"
						data-cy="Grupos"
						:btnLabel="
							() =>
								grupoSelecionado[0]
									? grupoSelecionado[0].nome
									: 'Selecione'
						"
					/>
				</div>

				<div class="form-group col-md-6">
					<label for="i_tipo">Tipo *</label>
						<vue-multi-select
							class="multi-100"
							v-model="tipoVariavel"
							historyButton
							:options="{ multi: false, labelName: 'nome' }"
							:selectOptions="tiposOpcoes"
							:btnLabel="() => tipoVariavel[0] ? tipoVariavel[0] : 'Selecione'"
						/>
				</div>

				<div class="form-group col-md-6" v-if="tipoVariavel[0] === 'Analógica'">
					<label class="mt-2">Unidade:</label>
					<b-form-input
						class="multi-100"
						v-model="unidadeInput"
					/>
				</div>

				<div class="form-group col-md-6" v-if="tipoVariavel[0] === 'Analógica'">
					<label class="mt-2">Taxa de amostragem (ms) *</label>
						<b-form-input
							class="multi-100"
							type="number"
							v-model="taxaAmostragem"
							min="0"
							step="1"
						/>
				</div>

				<div class="form-group col-md-6" v-if="tipoVariavel[0] === 'Analógica'">
					<label>Variação mínima *</label>
						<b-form-input
							class="multi-100"
							type="number"
							min="0"
							:formatter="formataParaDecimal"
							v-model="varMin"
						/>
				</div>

				<div class="form-group col-md-6" v-if="tipoVariavel[0] === 'Analógica'">
					<label>Valor mínimo:</label>
					<b-form-input
						type="number"
						v-model="valorMin"
					/>
				</div>

				<div class="form-group col-md-6" v-if="tipoVariavel[0] === 'Analógica'">
					<label>Valor máximo:</label>
					<b-form-input
						type="number"
						v-model="valorMax"
					/>
				</div>

				<div class="form-group col-md-6" v-if="tipoVariavel[0] === 'Digital'">
					<label class="mt-2">Estado ON:</label>
					<b-form-input class="multi-100" v-model="estadoOn" />
				</div>

				<div class="form-group col-md-6" v-if="tipoVariavel[0] === 'Digital'">
					<label class="mt-2">Estado OFF:</label>
					<b-form-input class="multi-100" v-model="estadoOff" />
				</div>

				<div class="form-group col-md-6" v-if="tipoVariavel[0] === 'Digital'">
					<label class="mt-2">Taxa de amostragem (ms) *</label>
					<b-form-input
						class="multi-100"
						type="number"
						min="0"
						step="1"
						v-model="taxaAmostragem"
					/>
				</div>

				<div class="form-group col-md-6" v-if="tipoVariavel[0] === 'Digital'">
					<label class="mt-2">Variação mínima *</label>
					<b-form-input
						class="multi-100"
						type="number"
						v-model="varMin"
						:formatter="formataParaDecimal"
						min="0"
					/>
				</div>
			</div>

			<p class="small text-right mt-3 mb-3 mr-3">
				Campos marcados com * são obrigatórios.
			</p>

			<div v-if="editing" class="d-flex justify-content-between">
				<button
					class="btn btn-secondary align-self-center mr-2"
					type="button"
					data-cy="Cancelar"
					@click.stop="$emit('cancelar')"
				>
					Cancelar
				</button>
				<button
					class="btn btn-danger align-self-center mr-auto"
					type="button"
					data-cy="Remover"
					@click="remover"
					:disabled="loading || saving || !editing"
				>
					Remover
				</button>
				<button
					class="btn btn-success align-self-center"
					type="submit"
					data-cy="Salvar"
					:disabled="
						loading ||
							saving ||
							(taxaAmostragem === null || varMin.length === 0
								? true
								: false ||
									!grupoSelecionado[0] ||
									!tipoVariavel[0] ||
									!tagInput)
					"
					@click="salvarEdicao"
				>
					Salvar
				</button>
			</div>

			<div v-if="!editing" class="d-flex justify-content-end">
				<button
					class="btn btn-success align-self-center lg"
					type="submit"
					data-cy="Salvar"
					:disabled="
						loading ||
							saving ||
							!varMin.length ||
							!grupoSelecionado[0] ||
							!tagInput ||
							taxaAmostragem === null
					"
					@click="adicionar"
				>
					Adicionar
				</button>
			</div>
		</div>
	</form>
</template>

<script>
	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";

	import { VariaveisService } from "../services/variaveis";
	import { GruposService } from "../services/grupos";

	function options (timeout) {
		return {
			timeout: timeout || 2000,
			showProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true
		};
	}

	export default {
		name: "AddEditVariavel",
		components: {
			VueMultiSelect
		},

		props: {
			itemId: Number
		},

		watch: {
			itemId: {
				handler: "onChange",
				immediate: true
			}
		},

		data () {
			return {
				tagInput: "",

				gruposOpcoes: [],
				grupoSelecionado: [],

				tipoVariavel: [],
				tiposOpcoes: [],

				unidadeInput: "",
				variavelInput: "",
				descricaoInput: "",

				estadoOn: "",
				estadoOff: "",

				valorMin: null,
				valorMax: null,

				taxaAmostragem: null,
				varMin: null,

				variavel: [],

				errMsg: "",

				loading: false,
				editing: false,
				saving: false,
				deleting: false,

				gruposService: new GruposService(),
				variaveisService: new VariaveisService()
			};
		},

		async mounted () {
			this.getOptions();
		},

		methods: {
			onChange () {
				if (this.itemId > 0)
					this.editar();
				else
					this.novoItem();
			},

			formataParaDecimal (value) {
				if (value)
					return parseFloat(value).toFixed(3);
			},

			novoItem () {
				this.editing = false;

				this.grupoSelecionado.splice(0);

				this.tagInput = "";
				this.descricaoInput = "";

				this.taxaAmostragem = 0;
				this.varMin = "0.000";
				this.tipoVariavel = [];

				this.valorMin = 0;
				this.valorMax = 0;
				this.unidadeInput = "";

				this.estadoOn = "";
				this.estadoOff = "";
			},

			async getOptions () {
				this.gruposOpcoes = (
					(await this.gruposService.listGroups()) || []
				).map(g => ({
					name: g.nome,
					...g
				}));
				this.tiposOpcoes = ["Analógica", "Digital"];
			},

			async adicionar () {
				try {
					this.saving = true;

					const payload = {
						tag: this.tagInput,
						descricao: this.descricaoInput,
						id_grupo: this.grupoSelecionado.map(g => g.id),
						tipo:
							this.tipoVariavel[0] === "Analógica"
								? (this.tipoVariavel[0] = "ANALOGICA")
								: "DIGITAL",
						valor_minimo: this.valorMin,
						valor_maximo: this.valorMax,
						unidade: this.unidadeInput,
						estado_on: this.estadoOn,
						estado_off: this.estadoOff,
						taxa_amostragem: this.taxaAmostragem,
						variacao_minima: this.varMin
					};

					await this.variaveisService.addVariables(payload);

					this.$emit("salvar-adicao", this.itemId);
					this.$snotify.success("Variável adicionada", "Sucesso!");
				} catch (err) {
					this.saving = false;

					console.log(err);
					this.$snotify.async(
						"Aguarde...",
						() => new Promise((resolve, reject) => {
							setTimeout(() => reject({
								title: "Erro ao salvar",
								config: options()
							})
							);
						})
					);
				}
			},

			async editar () {
				this.editing = true;

				const variaveis = await this.variaveisService.listVariables();
				const variavel = variaveis.find(v => v.id === this.itemId);

				const grupo = await this.gruposService.listGroups();
				this.grupoSelecionado[0] = grupo.find(v => v.id === variavel.id_grupo);

				this.tagInput = variavel.tag;
				this.descricaoInput = variavel.descricao;

				this.taxaAmostragem = variavel.taxa_amostragem;
				this.varMin = variavel.variacao_minima;
				this.tipoVariavel = variavel.analogica ? ["Analógica"] : ["Digital"];

				this.valorMin = variavel.analogica ? variavel.analogica.valor_minimo : "";
				this.valorMax = variavel.analogica ? variavel.analogica.valor_maximo : "";
				this.unidadeInput = variavel.analogica ? variavel.analogica.unidade : "";

				this.estadoOn = variavel.digital ? variavel.digital.estado_on : "";
				this.estadoOff = variavel.digital ? variavel.digital.estado_off : "";
			},

			async salvarEdicao () {
				const id = this.itemId;

				try {
					this.saving = true;

					const payload = {
						id_grupo: this.grupoSelecionado[0].id,
						tag: this.tagInput,
						descricao: this.descricaoInput,

						tipo:
							this.tipoVariavel[0] === "Analógica"
								? (this.tipoVariavel[0] = "ANALOGICA")
								: "DIGITAL",
						taxa_amostragem: this.taxaAmostragem,
						variacao_minima: this.varMin,

						valor_minimo: this.valorMin,
						valor_maximo: this.valorMax,
						unidade: this.unidadeInput,

						estado_on: this.estadoOn,
						estado_off: this.estadoOff
					};

					await this.variaveisService.updateVariables(+id, payload);

					this.$emit("salvar-edicao", this.itemId);
					this.$snotify.success("Variável salva", "Sucesso!");
				} catch (err) {
					this.saving = false;

					console.log(err);
					this.$snotify.async(
						"Aguarde...",
						() => new Promise((resolve, reject) => {
							setTimeout(() => reject({
								title: "Erro ao salvar",
								config: options()
							})
							);
						})
					);
				}
			},

			async cancelar () {
				this.saving = false;
			},

			async apagar () {
				const id = this.itemId;
				this.variaveis = await this.variaveisService.listVariables();

				const idx = this.variaveis.findIndex(c => c.id === +id);
				if (idx >= 0) {
					this.variaveis.splice(idx, 1);
				}
			},

			async remover () {
				const id = this.itemId;
				const variavel = await this.variaveisService.listVariables();
				const tagVar = variavel.find(v => v.id === +id && v.tag);

				this.deleting = true;

				const doDelete = () => this.variaveisService.deleteVariables(id)
					.then(res => res.data.error ? Promise.reject(res.data.error) : res.data)
					.catch(reason => {
						if (
							reason.response &&
							reason.response.data &&
							reason.response.data.error
						) {
							this.$swal.showValidationMessage(
								"Falha ao excluir: " +
									reason.response.data.error
							);

							this.deleting = false;
						}
					});

				this.$swal
					.fire({
						title: "Você tem certeza?",
						text: `A remoção de ${tagVar.tag} é irreversível`,
						type: "warning",
						showCancelButton: true,
						reverseButtons: true,
						focusCancel: true,
						confirmButtonColor: "#dc3545",
						confirmButtonText: "Remover",
						cancelButtonText: "Cancelar",
						allowOutsideClick: () => !this.$swal.isLoading(),
						showLoaderOnConfirm: true,
						preConfirm: doDelete
					})
					.then(res => {
						if (res.value) {
							this.$emit("remover-variavel", this.itemId);

							this.$snotify.success("Variável removida", "Sucesso!");
							this.apagar(+id);
						}
					});
			}
		}
	};
</script>
